import React from 'react'
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { IconArrow } from '../ui/IconArrow/IconArrow';

const animateSvg = keyframes`
    0% {
        stroke-dasharray: 110;
        stroke-dashoffset: 400;
        transform: rotate(360deg);
    }
`;

const animateIconArrow = keyframes`
    40% {
        transform: translate(10px);
        width: 0px;
    }
    70% {
        transform: translate(-10px);
        width: initial;
    }
`;

const WrapperLink = styled('span')`
    display: contents;
    a {
        display: inline-block;
        text-decoration: none;
        color: ${({theme}) => theme.colors.secondary};
        margin: 20px 0px;
        display: flex;
        align-items: center;

        &.white {
            color: #fff;
        }

        &.black {
            color: ${({theme}) => theme.colors.main};
            transition: all 200ms ease-in-out;
            span, span::after, span:before {
                background: ${({theme}) => theme.colors.main}
            }
            svg circle {
                stroke: #fff!important;
            }

            &:hover {
                color: #fff;
            }
        }

        &:hover {
            color: ${({theme}) => theme.colors.main};
            ${IconArrow} {
                span {
                    background: ${({theme}) => theme.colors.main}!important;
                    animation: ${animateIconArrow} 600ms ease-in-out;
                    &::after, &:after, &:before, &::before {
                        background: ${({theme}) => theme.colors.main}!important;
                    }
                }
            }

            svg {
                animation: ${animateSvg} 800ms linear;
            }
        }
    }
`;

const WrapperButton = styled('span')`
    background: ${({theme}) => theme.colors.secondary};
    display: block;
    border-radius: 40px;
    pointer-events: none;
    a {
        pointer-events: auto;
        display: flex;
        align-items: center;
        padding: 4px 20px;
        color: #fff;
        text-decoration: none;
        span {
            transition: all 200ms ease-in-out;
            background: #fff;
            &:after, &::after, &:before, &::before {
                background: #fff;
            }
        }
        &:hover {
            color: ${({theme}) => theme.colors.main};
            span {
                transform: translateX(5px);
                background: ${({theme}) => theme.colors.main};
                &:after, &::after, &:before, &::before {
                    background: ${({theme}) => theme.colors.main};
                }
            }
        }
    }
`;


interface LinkButtonProps {
    text: string
    url: string
    isWhite?: boolean
    isBlack?: boolean
    targetBlank?: boolean
    externalLink?: boolean
    isButton?: boolean
    onClick?: () => void
    id?: string
}

const LinkButton: React.FC<LinkButtonProps> = (props) => {
    if (!props.isButton) return (
        <WrapperLink
            onClick={() => props.onClick ? props.onClick() : undefined}>
            {props.externalLink ? renderAHref() : renderLinkRouterDom()}
        </WrapperLink>
    )
    else return (
        <WrapperButton
            onClick={() => props.onClick ? props.onClick() : undefined}>
            {props.externalLink ? renderAHref() : renderLinkRouterDom()}
        </WrapperButton>
    );

    function renderAHref(){
        return (
            <a
                id={props.id ?? ''}
                href={props.url}
                className={`${props.isWhite ? 'white' :  ''} ${props.isBlack ? 'black' :  ''}`}
                target={props.targetBlank ? '_blank' : '_self'} rel="noreferrer">
                {props.text}
                {renderCircleButton()}
            </a>
        )
    }

    function renderLinkRouterDom(){
        return (
            <Link 
                id={props.id ?? ''}
                to={props.url} className={props.isWhite ? 'white' :  ''}>
                {props.text}
                {renderCircleButton()}
            </Link>
        );
    }

    function renderCircleButton() {
        return (
            <IconArrow flex align={'center'} justify={'center'} className={props.isWhite ? 'white' :  ''}>
                <span></span>
                {renderSvgCircle()}
            </IconArrow>
        );
    }

    function renderSvgCircle(){
        return (
            <svg height="40" width="40">
                <circle cx="20" cy="20" r="18" stroke={props.isWhite ? '#fff' : '#7daed3'} strokeWidth="1" fill="transparent" />
            </svg>
        );
    }
}

export default LinkButton;
