// eslint-disable-next-line
export default {

    LINK_CHECKOUT_SHOPIFY: 'https://shop.farmaka.com/cart/',

    CTA_GO_BACK: 'Torna indietro',
    CTA_DISCOVER_PRODUCT: 'Scopri i prodotti',
    CTA_DISCOVER_SHOP: 'Vai allo shop',
    CTA_GO_TO_CHECKOUT: 'Vai al Checkout',
    CTA_SKINCARE: 'Skin Under Control',
    CTA_NEWS: 'Leggi le ultime News',

    NAVIGATION_COLUMN_1: [
        { name: 'Shop', path: 'https://shop.farmaka.com/collections/frontpage', externalLink: true},
        { name: 'Prodotti', path: 'https://shop.farmaka.com/collections/frontpage', externalLink: true},
        { name: 'News', path: '/news', }
    ],

    NAVIGATION_COLUMN_2: [
        { name: 'Chi Siamo', path: 'https://www.farmaka.com/company', externalLink: true},
        { name: 'Contattaci', path: '/contact' },
        { name: 'Store Locator', path: '/store-locator' }
    ],

    NAVIGATION_COLUMN_3: [
        { name: 'Privacy Policy ', path: 'https://www.iubenda.com/privacy-policy/37144746', externalLink: true },
        { name: 'Cookie Policy', path: 'https://www.iubenda.com/privacy-policy/37144746/cookie-policy', externalLink: true }
    ],

    BANNER_LOOP: {
        text: 'spedizione gratuita senza minimo di spesa',
        date: {
            start: '2022-05-30T23:59:00',
            end: '2022-06-14T23:59:00',
        }
    },

    CAROUSEL_HERO: [
        {
            id: 0,
            title: '4 GESTI, 4 PRODOTTI, 40% DI SCONTO + POCHETTE OMAGGIO',
            image: 'item-3.png',
            image_mob: 'item-3-mob.png',
            description: 'Con l’acquisto dell’intero rituale Skin Under Control, riceverai uno sconto del 40% e una pochette in cotone in omaggio!',
            note: '*fino ad esaurimento scorte',
            href: 'https://shop.farmaka.com/collections/frontpage/products/bundle-okne'
        },
        {
            id: 1,
            title: 'PROMO 25% INTEGRATORE + DETERGENTE',
            image: 'item-1.png',
            image_mob: 'item-1-mob.png',
            description: 'A fronte dell’acquisto in coppia integratore + detergente, riceverai uno sconto del 25%!',
            href: 'https://shop.farmaka.com/collections/frontpage/products/combo-integratore-detergente'
        },
        {
            id: 2,
            title: 'PROMO 25% INTEGRATORE + NORMALIZZANTE',
            image: 'item-2.png',
            image_mob: 'item-2-mob.png',
            description: 'Acquista la combo integratore + normalizzante e approfitta dello sconto del 25%!',
            href: 'https://shop.farmaka.com/collections/frontpage/products/combo-integratore-normalizzante'
        }
    ],

    HERO_TITLE: 'OKNE <br /> <span>SKIN UNDER CONTROL</span>',
    HERO_TEXT: 'OKNE la tua linea di prodotti per la pelle.',

    SECTION_BRAND_TITLE: 'OKNE BRAND',
    SECTION_BRAND_TEXT: 'OKNE non è una semplice linea di prodotti per la pelle: è un vero e proprio trattamento IN & OUT in 4 fasi che tiene le imperfezioni della tua pelle sotto controllo. E lo fa sia con prodotti ad uso esterno che con un integratore alimentare probiotico che combatte dall’interno.',

    PRODUCTS: [
        { 
            id: 39819004149839,
            path: 'bundle-okne',
            img: 'okne-bundle.png',
            imgHover: 'okne-bundle-hover.png',
            price: 33.96,
            title: 'Bundle completo OKNE + pochette OMAGGIO',
            description: 'linea OKNE completa di tutti e quattro i suoi prodotti per una routine completa + la pochette OKNE in 100% cotone in omaggio*',
            howToUse: '',
            descriptionLong: 'Integratore Alimentare Probiotico riequilibra la flora intestinale e ha un effetto sulla pelle e sulla salute. Crema Normalizzante a pH acido che lavora in modo specifico sul pH della pelle. Crema Opacizzante il prodotto ideale per opacizzare la pelle, rendere il colorito del viso più uniforme e contrastare l’effetto lucido tipico della pelle a tendenza acneica. Detergente Delicato che pulisce in profondità rispettando il pH fisiologico della pelle.',
            inci: "",
            format: 'Bundle',
            isPurchasable: true
        },
        { 
            id: 40018984599631,
            path: 'combo-integratore-detergente',
            img: '01.png',
            imgHover: '02.png',
            price: 23.85,
            title: 'COMBO Integratore + Detergente',
            description: "Offerta combo Integratore Alimentare Probiotico + Detergente Delicato. Mantieni il naturale equilibrio della tua pelle con l'integratore e il detergente OKNE: la combo perfetta per la stagione estiva!",
            howToUse: '',
            descriptionLong: 'Integratore Alimentare Probiotico riequilibra la flora intestinale e ha un effetto sulla pelle e sulla salute. Detergente ﻿Delicato che pulisce in profondità rispettando il pH fisiologico della pelle. Un prodotto non aggressivo che lascia la pelle morbida e pulita e che ne mantiene il naturale equilibrio.',
            inci: "",
            format: 'Bundle',
            isPurchasable: true
        },
        { 
            id: 40018876661839,
            path: 'combo-integratore-normalizzante',
            img: '03.png',
            imgHover: '04.png',
            price: 26.10,
            title: 'COMBO Integratore + Normalizzante',
            description: "Offerta combo Integratore Alimentare Probiotico + Crema Normalizzante. I primi due step del trattamento Skin Under Control, se stai cercando il rituale perfetto per la tua skincare routine ma non sai da dove partire.",
            howToUse: "",
            descriptionLong: "Integratore Alimentare Probiotico riequilibra la flora intestinale e ha un effetto sulla pelle e sulla salute. Crema Normalizzante a pH acido che lavora in modo specifico sul pH della pelle. L'utilizzo di prodotti a pH acido combatte i batteri responsabili dei brufoli e delle imperfezioni.",
            inci: "",
            format: 'Bundle',
            isPurchasable: true
        },
        {
            id: 31697959682127,
            path: 'integratore-alimentare',
            img: 'okne-bustina.png',
            imgHover: 'okne-bustina-hover.png',
            price: 19.90,
            title: 'OKNE Integratore Alimentare Probiotico',
            description: 'Riequilibra la cute e la flora intestinale',
            howToUse: 'Il contenuto di ogni bustina monodose si scioglie direttamente in bocca o eventualmente in un bicchiere d’acqua. Va assunto lontano dai pasti, preferibilmente la mattina appena svegli. Si consiglia l’assunzione per almeno un mese.',
            descriptionLong: 'La flora intestinale ha un effetto sulla pelle e sulla salute. Le imperfezioni della pelle a tendenza acneica possono essere il riflesso di uno squilibrio della flora batterica intestinale. OKNE Integratore Alimentare Probiotico contiene uno specifico ceppo batterico, il Lactobacillus rhamnosus LR06, che arriva vivo e attivo nell’intestino grazie a una tecnologia brevettata che lo protegge dai succhi gastrici. *Senza glutine e privo di lattosio',
            ingredienti: "Edulcoranti: Sorbitolo e Xilitolo; Agente di carica: Maltodestrina; Beta-carotene (precursore della Vit. A); Microrganismo probiotico microincapsulato: Lactobacillus rhamnosus LR06 (DSM 21981); Zinco gluconato; Aroma di lampone; Calcio D-pantotenato (fonte di Acido pantotenico - Vit. B5); Antiagglomerante: Biossido di silicio; Riboflavina (Vit. B2) ricoperta; Piridossina cloridrato (Vit. B6) ricoperta.",
            format: '14 stick da 1,6 g',
            isPurchasable: true
        },
        { 
            id: 31697989206095,
            path: 'detergente-delicato',
            img: 'okne-detergente.png',
            imgHover: 'hover-item-2.png',
            price: 11.90,
            title: 'OKNE Detergente Delicato',
            description: 'Rimuove il sebo in eccesso',
            howToUse: 'Mattina e sera, massaggiare il gel sulla pelle umida fino a quando si forma una leggera schiuma, lasciare agire per qualche istante e risciacquare. Asciugare tamponando delicatamente, senza sfregare.',
            descriptionLong: 'Mattina e sera, lavarsi il viso è fondamentale. Ma come farlo in modo corretto? È importante utilizzare un detergente che non secchi la pelle e non ne alteri il pH. OKNE ha creato un detergente delicato che pulisce in profondità rispettando il pH fisiologico della pelle. Un prodotto non aggressivo che lascia la pelle morbida e pulita e che ne mantiene il naturale equilibrio.',
            inci: "Aqua, Sodium Coceth Sulfate, Decyl Glucoside, Cocamidopropyl Betaine, Hamamelis Virginiana Leaf Extract, Glycerin, Betaine, Phenethyl Alcohol, Quaternium-22, Acrylates/C10-30 Alkyl Acrylate Crosspolymer, Linalyl Acetate, Propylene Glycol, Propanediol, Aloe Barbadensis Leaf Juice, Salix Nigra Bark Extract, Ethylhexylglycerin, Disodium Edta, Panthenol, Xanthan Gum, Isopropyl Myristate, Potassium Azeloyl Diglycinate, Sodium Hydroxide, Krameria Triandra Extract, Sodium Benzoate, Rhamnose, Potassium Sorbate, Glucose, Glucuronic Acid, Maltodextrin",
            format: '200 ml',
            isPurchasable: true
        },
        { 
            id: 31729138040911,
            path: 'crema-normalizzante',
            img: 'okne-crema-normalizzante.png',
            imgHover: 'hover-item-3.png',
            price: 14.90,
            title: 'OKNE Crema Normalizzante',
            description: 'Normalizza e protegge la pelle',
            howToUse: 'Mattina e sera, applicare un sottile strato su tutto il viso asciutto dopo la detersione. Evitare il contorno occhi. Non è necessario risciacquare. Aspettare qualche minuto prima di applicare altri prodotti o truccarsi. Si può usare tutti i giorni.',
            descriptionLong: "La crema normalizzante a pH acido lavora in modo specifico sul pH della pelle. Perché il pH è così importante? L'utilizzo di prodotti a pH acido combatte i batteri responsabili dei brufoli e delle imperfezioni. Agendo sul pH e riducendo la produzione di sebo la pelle viene tenuta sotto controllo.",
            inci: "Aqua, Squalane, Triethylhexanoin, Behenyl Alcohol, PPG-12/SMDI Copolymer, Cetearyl Alcohol, Glyceryl Stearate, Polyglyceryl-10 Pentastearate, Butylene Glycol, Potassium Palmitoyl Hydrolyzed Wheat Protein, Phenethyl Alcohol, Lecithin, Krameria Triandra Root Extract, Caprylyl Glycol, Escin, Xanthan Gum, Sodium Stearoyl Lactylate, Lauric Acid, Linalyl Acetate, Beta-Sitosterol, Disodium EDTA, Galactaric Acid, Isopropyl Myristate, CI 42090, CI 19140.",
            format: '30 g',
            isPurchasable: true
        },
        { 
            id: 31698006474831,
            path: 'crema-opacizzante',
            img: 'okne-crema-opacizzante.png',
            imgHover: 'hover-item-1.png',
            price: 9.90,
            title: 'OKNE Crema Opacizzante',
            description: 'Contrasta l\'effetto lucido',
            howToUse: 'Applicare una piccola quantità sul viso, evitando il contorno occhi. Aspettare qualche minuto prima di applicare altri prodotti o truccarsi. Si può usare tutti i giorni, ogni volta che se ne ha bisogno.',
            descriptionLong: 'Uno degli aspetti più odiati della pelle a tendenza acneica è l’effetto lucido. La sensazione di avere la pelle unta e mai pulita. OKNE Crema Opacizzante ha come obiettivo quello di opacizzare la pelle, contrastare l’effetto lucido e rendere il colorito del viso più uniforme.',
            inci: "Aqua, Ethylhexyl Methoxycinnamate, Olus Oil, Ethylhexyl Palmitate, Butyl Methoxydibenzoylmethane, Titanium Dioxide, Butylene Glycol, Cetearyl Alcohol, Propylene Glycol, Hamamelis Virginiana Leaf Water, Ceteareth-20, Polysorbate 60, Isostearyl Alcohol, Dimethicone, Sodium Citrate, Propanediol, Phenethyl Alcohol, Limnanthes Alba Seed Oil, Hydrogenated Castor Oil, Polyacrylamide, Silica, Xanthan Gum, C13-14 Isoparaffin, Aloe Barbadensis Leaf Juice, Potassium Azeloyl Diglycinate, Triethoxycaprylylsilane, Salix Nigra Bark  Extract, Linalyl Acetate, Ethylhexylglycerin, Citric Acid, Prunus Amygdalus Dulcis Oil, Tocopheryl Acetate, Hydrogenated Lecithin, Laureth-7, Butyrospermum Parkii Butter Extract, Krameria Triandra Extract, Isopropyl Myristate, Rhamnose, Maltodextrin, Glucose, Glucuronic Acid, Sodium Benzoate, Potassium Sorbate",
            format: '40g',
            isPurchasable: true
        }
    ],

    FAQ_TITLE: 'FAQ sui prodotti OKNE:',
    FAQs: [
        { title: 'Che cos’è “Skin Under Control”?', content: 'OKNE ti aiuta a valorizzare il tuo gioiello più prezioso: la tua pelle. Per questo abbiamo pensato a un processo composto da 4 step e 4 prodotti.'},
        { title: 'Dove posso acquistare i prodotti OKNE?', content: `I prodotti OKNE sono venduti nello <a href='https://shop.farmaka.com/collections/frontpage'>shop online</a> e in farmacie selezionate. Per conoscere il punto vendita più vicino puoi contattare il nostro Servizio Clienti al seguente indirizzo mail: <a href='mailto:servizioclienti@farmaka.com'>servizioclienti@farmaka.com</a> (da lunedì a venerdì, dalle 9 alle 18).`},
        { title: 'Come ricevere consigli sugli ultimi prodotti OKNE?', content: `Per conoscere meglio i prodotti OKNE puoi contattare il nostro Servizio Clienti al seguente indirizzo mail: <a href='mailto:servizioclienti@farmaka.com'>servizioclienti@farmaka.com</a> (dal lunedì al venerdì, dalle 9 alle 18).`},
        { title: 'I prodotti OKNE sono dermatologicamente testati?', content: `Tutta la linea è dermatologicamente testata.`},
        { title: 'È possibile ricevere dei campioni gratuiti di prova?', content: `Per richiedere un campione gratuito di prova puoi contattare il nostro Servizio Clienti al seguente indirizzo mail: <a href='mailto:servizioclienti@farmaka.com'>servizioclienti@farmaka.com</a> (dal lunedì al venerdì, dalle 9 alle 18).`},
        { title: 'I prodotti OKNE sono testati sugli animali?', content: `Come da Regolamento (CE) n.1223/2009 a tutti i prodotti OKNE si applica quanto segue: non vengono eseguiti né commissionati esperimenti su animali nello sviluppo, nella produzione e nella fase di test dei prodotti.`},
        { title: 'Dove vengono fabbricati i prodotti OKNE?', content: `Tutti i prodotti della linea OKNE vengono sviluppati, prodotti e confezionati in Italia.`}
    ],

    SECTION_SKINCARE_TITLE: '4 step per la tua skincare routine',
    SECTION_SKINCARE_TEXT: '4 gesti, 4 prodotti, 4 step al giorno.',

    SECTION_NEWS_CATEGORY: 'NEWS',
    SECTION_NEWS_TITLE: 'Stiamo cambiando la bellezza in meglio',

    NEWS: [
        {
            category: "SKIN UPDATES",
            title: "OKNE acquistabile anche su Farmaè",
            content: "Da oggi puoi acquistare tutta la linea OKNE anche su Farmaè. Approfittane subito!",
            url: "https://www.farmae.it/prodotti?ditta=5860",
            cta: "Scopri di più",
            background: "#7DAED3"
        },
        {
            category: "SKIN UPDATES",
            title: "Torna la promo 20% #amicheperlapelle",
            content: "Tornano le #amicheperlapelle Lea e Federica con la promozione -20% su tutti i prodotti.",
            url: 'https://shop.farmaka.com/collections/frontpage',
            cta: "Scopri di più",
            background: "#CFAD96"
        },
        {
            category: "SKIN UPDATES",
            title: "Da oggi puoi acquistare OKNE anche su Amazon",
            content: "Scopri il Rituale OKNE per tenere sotto controllo i brufoli! Da oggi ci puoi trovare anche su Amazon.",
            url: "https://www.amazon.it/s?k=Farmaka+srl&ref=bl_dp_s_web_0",
            cta: "Scopri di più",
            background: "#C98F78"
        },
        {
            category: "SKIN UPDATES",
            title: "Diventa subito Tester con OKNE",
            content: "Imperfezioni, brufoli, punti neri ti tormentano? Rispondi alle domande del questionario! Potresti ricevere i nostri prodotti e diventare #mybeautytester. ",
            url: "https://www.mybeauty.it/hidden/diventa-tester-con-okne",
            cta: "Scopri di più",
            background: "#CFAD96"
        },
        {
            category: "SKIN UPDATES",
            title: "Da oggi OKNE anche su efarma.com",
            content: "Da oggi puoi acquistare tutta la linea Okne su efarma.com. Vieni a scoprirla, con l’acquisto un campione omaggio dell’Integratore.",
            url: "https://www.efarma.com/okne.html",
            cta: "Scopri di più",
            background: "#7DAED3"
        }
    ],
    SECTION_NEWSLETTER_TITLE: 'Non perderti tutti gli skin updates.<br />Rimaniamo in contatto!',
    CART_EMPTY_TITLE: 'Il tuo carrello è vuoto'
};