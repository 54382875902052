import React from 'react';
import { Wrapper } from '../ui/Wrapper/Wrapper';
import { Text } from '../ui/Typography/Typography';
import styled from 'styled-components';
import LinkButton from '../LinkButton/LinkButton';
import { TitleH5 } from '../ui/Title/Title';

const WrapperCard = styled(Wrapper)`

`;

interface CardNewsProps {
    title: string
    description: string
    color: string
    category: string
    url: string | null
    cta: string
}

const CardNews: React.FC<CardNewsProps> = (props) => {
    return (
        <WrapperCard padding={'30px'} backgroundColor={props.color} margin={'10px 0px'}>
            {renderHeadCard()}
            {renderBodyCard()}
            {renderLinkCard()}
        </WrapperCard>
    );

    function renderHeadCard(){
        return (
            <Wrapper width={'100%'}>
                <Text white>{props.category}</Text>
            </Wrapper>
        );
    }

    function renderBodyCard(){
        return (
            <Wrapper margin={'40px 0px 30px 0px'}>
                <TitleH5 weight={400}>{props.title}</TitleH5>
                <Text margin={'30px 0px'}>{props.description}</Text>
            </Wrapper>
        )
    }

    function renderLinkCard() {
        if(props.url ) return (
            <Wrapper width={'100%'} margin={'30px 0px'}>
                <LinkButton text={props.cta} url={props.url} isWhite targetBlank externalLink/>
            </Wrapper>
        );
        else return null;
    }
}

export default CardNews;
