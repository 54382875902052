import React, { useState } from 'react';
import styled from 'styled-components';
import { FaInstagram, FaFacebookF, FaArrowRight } from "react-icons/fa";
import { getLocalImage } from '../../helpers/getLocalImages';
import { Col, Container, Row } from '../ui/GridSystem/GridSystem';
import { Link } from 'react-router-dom';
import Constants from '../../config/Constants';
import {Text} from '../ui/Typography/Typography';
import { Wrapper } from '../ui/Wrapper/Wrapper';
import validator from 'validator';

const Ul = styled.ul`
    list-style-type: none;
    padding: 0px 0px;
    margin: 40px 0;

    a {
        color: ${({theme}) => theme.colors.main};
        text-decoration: none;
    }

    a:hover {
        color: white;
        transition: all 150ms ease-in-out;
    }

    li {
        width: 150px;
        margin-bottom: 15px;

        &:last-child{
            margin-bottom: 0px;
            margin-left: 0px;
        }

        &.icon-list a {
            float: left;
            margin-right: 15px;
        }
    }
`;

const NewsletterForm = styled('div')`
    border-bottom: 2px solid black;
        input {
            margin: 40px 10% 20px 0;
            width: 80%;
            background: none;
            border: none;
            ::placeholder {
                color: ${({theme}) => theme.colors.main};
            }
            input:focus-visible {
                border:none;
            }
        }
        input:focus {
            outline: none;
        }

        button {
            font-size: 12px;
            font-weight: 700;
            color: ${({theme}) => theme.colors.main};
            background: none;
            border: 2px ${({theme}) => theme.colors.main} solid;
            border-radius: 1000px;
            cursor: pointer;
            outline: none;
            padding: 5px 6px;
        }
        button:hover {
            color: white;
            transition: all 0.2s linear;
            border: white 2px solid;
        }
        button:onChange {
            border: 0px;
        }
`;

const Check = styled('div')`
    font-size: 10px;
    margin: 20px 0;
    a {
        color: ${({theme}) => theme.colors.main};
    }
`;
const CopyrightText = styled('div')`
    margin: 40px 0;
`;
const LogoFooter = styled('div')`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 60px;
    img {
        max-width: 100%;
    }
`;

const WrappeContact = styled(Wrapper)`
    a {
        color: ${({theme}) => theme.colors.main};
        margin-top: 10px;
        display: inline-block;
    }
`;

type NavigationType = {name: string | any, path: string, externalLink?: boolean};

const NAVIGATION_SOCIALS: NavigationType[] = [
    { name:  <FaFacebookF size={'1.4em'} />, path: 'https://www.facebook.com/oknefarmaka/', externalLink: true },
    { name:  <FaInstagram  size={'1.5em'} />, path: 'https://www.instagram.com/okne.it/?utm_medium=copy_link', externalLink: true }
];

function Footer() {

    const [mail, setmail] = useState<string>('');
    const [check, setcheck] = useState<boolean>(false);
    const [errorMail, setErrorMail] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [validMessage, setValidMessage] = useState<boolean>(false);


    return (
        <Wrapper backgroundColor='secondary' paddingTop={'40px'} position='relative'>
            <Container>
                <Row>
                    {renderInfoBrand()}
                    {renderContact()}
                    {renderNavigationColumn(Constants.NAVIGATION_COLUMN_3, 2, false)}                    
                    {renderNavigationColumn(NAVIGATION_SOCIALS, 2, true)}
                    {newsletterForm()}
                </Row>
                <Row>
                <LogoFooter>
                    <img src={getLocalImage('okne-logo-footer.png')} alt={'Logo Okne'} />
                </LogoFooter>
                </Row>
            </Container>
        </Wrapper>
);

    function renderInfoBrand(){
        return (
            <Col xl={2} lg={2} md={2} sm={6} xs={12}>
                <Text primary margin={'44px 0px 20px 0px'}>Farmaka s.r.l. <br/> Via Villapizzone, 26  <br/> 20156, Milano <br/> P.IVA/C.F. 04899270153</Text>
            </Col>
        );
    }

    function renderContact(){
        return (
            <Col xl={2} lg={2} md={2} sm={6} xs={12}>
                <WrappeContact>
                    <Text primary margin={'44px 0px 20px 0px'}>
                        Per informazioni: <br />
                        T. +39 02 33497026 <br />
                        <a href={'mailto:mailbox@farmaka.com'}>mailbox@farmaka.com</a>
                        <br/><br/>
                        Per richieste commerciali:<br/>
                        <a href={'mailto:servizioclienti@farmaka.com'}>servizioclienti@farmaka.com</a>

                    </Text>
                </WrappeContact>
            </Col>
        );
    }

    function renderNavigationColumn(nav: NavigationType[], sizeColumn: number, isIcons?: boolean) {
        const items = nav.map((x, i) => (
            <li key={i} className={isIcons ? 'icon-list' : ''}>
                {
                    x.externalLink ?
                        <a href={x.path} target="_blank" rel="noreferrer">{x.name}</a> :
                        <Link to={x.path}>{x.name}</Link>
                }
            </li>
        ));

        return (
            <Col xl={sizeColumn} lg={sizeColumn} md={sizeColumn} sm={12} xs={12}>
                <Ul>
                    {items}
                </Ul>
            </Col>
        );
    }

    function newsletterForm() {
        return (
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                <NewsletterForm>
                    <input type="text" placeholder="Il tuo indirizzo email..." onChange={(e) => setmail(e.target.value)} value={mail}></input>
                    <button onClick={() => handleNewsLetter()}><FaArrowRight></FaArrowRight></button>
                </NewsletterForm>
                <Check>
                    <p> <input type="checkbox" name="check"onClick={() => setcheck(!check)}/>
                    Desidero ricevere aggiornamenti su prodotti e iniziative di marketing, per cui presto il mio consenso al trattamento dei miei dati personali, secondo l'<Link to="/">informativa della privacy.</Link> </p> 
                </Check>
                {renderErrorMessage()}
                {renderValidMessage()}
                <CopyrightText>
                    <p> &copy;2021 OKNE, Fàrmaka. </p>
                </CopyrightText>
            </Col>
        ) 
    }

    function renderErrorMessage(){
        if (errorMail) return (
            <Text error margin='0 0 20px 0'>
                {errorMessage ? errorMessage : 'Inserisci una mail vailda'}
            </Text>
        );
        else return null;
    }

    function renderValidMessage(){
        if (validMessage) return (
            <Text primary margin='0 0 20px 0'>
                Grazie, registrazione completata
            </Text>
        );
        else return null;
    }

    async function handleNewsLetter() {
        setErrorMail(false)
        setErrorMessage(null)
        if (!validator.isEmail(mail)) return setErrorMail(true);
        if (!!!check) {
            setErrorMail(true);
            return setErrorMessage('Accetta termini condizioni');
        }
        else {
            setErrorMail(false);
            try {
                const url = 'https://hooks.zapier.com/hooks/catch/3939523/bmn2qbr/'
                const requestOptions = {
                    method: 'POST',
                    body: JSON.stringify({ email: mail })
                };
                await fetch(url, requestOptions );
                setValidMessage(true)
            } catch (error) {
                console.log(false)
            }
        }
    }
}

export default Footer;