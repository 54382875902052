import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getLocalImage } from '../../helpers/getLocalImages';
import { Wrapper } from '../ui/Wrapper/Wrapper';

interface ButtonScrollTopProps {

}

const WrapperButton = styled(Wrapper)`
    z-index: 10;
    bottom: 70px;
    right: 20px;
    box-shadow: 0px 8px 25px rgba(0,0,0,.08);
    cursor: pointer;
    &.hide {
        display: none;
    }
    img {
        max-width: 20px;
    }
`;

const ButtonScrollTop: React.FC<ButtonScrollTopProps> = (props) => {
    const SIZE_BUTTON: number = 70;
    let busy: boolean = false;

    const [isHide, setisHide] = useState<boolean>(true);

    useEffect(() => {
        window.addEventListener('scroll', () => handleScrolltop());
        return window.removeEventListener('scroll', () => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <WrapperButton
            width={`${SIZE_BUTTON}px`} height={`${SIZE_BUTTON}px`} borderRadius={`${SIZE_BUTTON}px`}
            flex align='center' justify='center'
            backgroundColor='white'
            position='fixed'
            className={isHide ? 'hide' : ''}
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
            <img src={getLocalImage('ic-arrow.svg')} alt="i arrow" />
        </WrapperButton>
    );

    function handleScrolltop(){
        const isScrollBreak = window.scrollY > window.innerHeight / 4;
        if (busy && isScrollBreak) return null;
        else {
            setTimeout(() => {
                setisHide(!isScrollBreak);
            }, 1500)
        }
    }
}
export default ButtonScrollTop;