import React from 'react'
import Constants from '../../config/Constants';
import FAQ from '../FAQ/FAQ';
import { TitleH3 } from '../ui/Title/Title';
import { Wrapper } from '../ui/Wrapper/Wrapper';


const SectionFAQ = () => {
    return (
        <Wrapper width='100%' padding={'40px 0px'} backgroundColor={'main'}>
            {renderTitle()}
            {renderFaqs()}
        </Wrapper>
    )

    function renderFaqs(){
        return Constants.FAQs.map((x, i) => (
            <FAQ key={i} title={x.title} content={x.content} />
        ))
    }

    function renderTitle(){
        return (
            <Wrapper padding='20px 10% 60px 10%'>
                <TitleH3 white weight={400}>{Constants.FAQ_TITLE}</TitleH3>
            </Wrapper>
        );
    }
}

export default SectionFAQ;
