import React, { useContext } from 'react'
import styled, { keyframes } from 'styled-components';
import { getLocalImage } from '../../helpers/getLocalImages';
import { Wrapper } from '../ui/Wrapper/Wrapper';
import { Text } from '../ui/Typography/Typography';
import { GlobalContext } from '../../hooks/context/Context';
import { CartActionType } from '../../hooks/reducers/CartReducers';
import { Link } from 'react-router-dom';
import { Product } from '../../Implementation/Models/Product';
import { ProductDTO } from '../../Implementation/DTOs/ProductsDTO';

const animationImageFadeOut = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        transform: scale(.8) translateY(10px);
    }
`;

const animationImageFadeIn = keyframes`
    0% {
        opacity: 0;
        transform: scale(.8) translateY(10px);
    }
    100% {
        transform: scale(1) translateY(0px);
    }
`;

const WrapperImage = styled(Wrapper)`
    overflow: hidden;
    position: relative;
    img {
        position: absolute;
        max-width: 100%;
        max-height: 100%;
        transition: all 300ms ease-in-out;
        &:last-child {
            opacity: 0;
        }
    }

    &:hover {
        img:first-child {
            opacity: 0;
            animation: ${animationImageFadeOut} 200ms ease-in-out;
        }
        img:last-child {
            opacity: 1;
            animation: ${animationImageFadeIn} 300ms ease-in-out;
        }
    }

`;

const WrapperButton = styled(Wrapper)`
    background: ${({theme}) => theme.colors.secondary};
    padding: 14px;
    width: fit-content;
    border-radius: 30px;
    cursor: pointer;
    transition: all 300ms ease-in-out;

    ${Text} {
        display: none;
        white-space: nowrap;
    }

    img {
        max-width: 18px;
        margin-top: -3px;
    }

    &:hover {
        padding: 14px 60px;
        ${Text} {
            display: block;
        }
    }
`;

const WrapperButtonEsaurito = styled(Wrapper)`
    padding: 10px 0px;
    color: ${({theme}) => theme.colors.error};
`;

const WrapperCard = styled(Wrapper)`
    a {
        text-decoration: none;
    }
`;

const WrapperTextCard = styled(Wrapper)`
    min-height: 180px;

    
`;


const CardProduct: React.FC<ProductDTO> = (props) => {
    const product = Product.createFromDTO(props);
    const context = useContext(GlobalContext)!;
    return (
        <WrapperCard width={'100%'} margin='30px 0px'>
            <Link to={product.path}>
                {renderImage()}
                {renderText()}
            </Link>
            {renderButtonAddCart()}
        </WrapperCard>
    )

    function renderImage() {
        return (
            <WrapperImage width={'100%'} height={'340px'}>
                <img src={getLocalImage(product.img)} alt="product" />
                <img src={getLocalImage(product.imgHover)} alt="Hover product" />
            </WrapperImage>
        );
    }

    function renderText(){
        return (
            <WrapperTextCard width={'100%'} padding={'20px 0px'}>
                <Text large secondary margin={'15px 0px'}>
                    {`€ ${product.price.toLocaleString('it-It', { style: 'currency', currency: 'EUR' }).replace('€', '')}`}
                </Text>
                <Text weight={'500'}>{product.title}</Text>
                <Text margin={'5px 0px'}>{product.description}</Text>
            </WrapperTextCard>
        );
    }

    function renderButtonAddCart(){
        if(product.isPurchasable) return(
            <WrapperButton
                id={`ayq-${product.id}`}
                flex align={'center'} justify={'center'} onClick={() => handleAddProductinCart()}>
                <Text white margin={'0px 15px 0px 0px'}>Add Cart</Text>
                <img src={getLocalImage('icon-cart.svg')} alt="cart"/>
            </WrapperButton>
        );
        else return (
            <WrapperButtonEsaurito>
                ESAURITO
            </WrapperButtonEsaurito>
        );
    }

    function handleAddProductinCart(){
        context.cart.cartDispatch({
            type: CartActionType.ADD_ITEMS_CART,
            payload: product
        });
    }
}

export default CardProduct;
