import React, { useEffect } from 'react'
import styled from 'styled-components';
import CardNews from '../components/CardNews/CardNews';
import { Col, Container, Row } from '../components/ui/GridSystem/GridSystem';
import { Title } from '../components/ui/Title/Title';
import { Wrapper } from '../components/ui/Wrapper/Wrapper';
import Constants from '../config/Constants';

const WrapperPage = styled(Wrapper)`
    width: 100%;
    padding: 140px 0px;
    position: relative;
    background: white;
`;

const PageNews = ()  => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <WrapperPage>
            {renderTitle()}
            {renderContainerCards()}
        </WrapperPage>
    )

    function renderTitle(){
        return (
            <Container>
                <Row>
                    <Col>
                        <Title margin={'20px 0px 80px 0px'}>Skin Updates</Title>
                    </Col>
                </Row>
            </Container>
        );
    }

    function renderContainerCards(){
        return (
            <Container>
                <Row>
                    {renderColumnsCard()}
                </Row>
            </Container>
        );
    }

    function renderColumnsCard(){
        return Constants.NEWS.map((x, i) => (
            <Col key={i} xl={4} lg={4} md={4} sm={6} xs={12}>
                <CardNews
                    category={x.category}
                    title={x.title}
                    description={x.content}
                    color={x.background}
                    url={x.url}
                    cta={x.cta}/>
            </Col>
        ))
    }
}

export default PageNews
