import styled, {css, DefaultTheme} from 'styled-components';
import { ThemeColorType } from '../../../config/theme';
import { FLEX_PROPS, IFlexProps } from '../IFlexProps/IFlexProps';

export interface WrapperStyledProps extends IFlexProps{
    padding?: string
    paddingTop?: string
    paddingBottom?: string
    paddingLeft?: string
    paddingRight?: string
    margin?: string
    marginTop?: string
    marginBottom?: string
    marginLeft?: string
    marginRight?: string
    backgroundColor?: ThemeColorType | string
    shadow?: boolean | 'small' | 'medium' | 'large'
    float?: 'left' | 'right' | 'none'
    width?: string
    height?: string
    relative?: boolean
    bgImage?: string
    bgCover?: boolean
    borderRadius?: string | boolean
    position?: 'relative' | 'absolute' | 'fixed'
    maxWidth?: string
    color?: string
}

const handleShadowWrapper = (theme: DefaultTheme, shadow: string) => {
    switch (shadow) {
        case 'small':
            return theme.shadow.medium;
        case 'medium':
            return theme.shadow.medium;
        case 'large':
            return theme.shadow.large;
    }
};

const handleFloatWrapper = (float: string) => {
    switch (float) {
        case 'left':
            return 'left';
        case 'right':
            return 'right';
        case 'none':
            return 'none';
    }
};

const handleBackgroundWrapper = (theme: DefaultTheme, color: ThemeColorType | string) => {
    const themeColors = theme.colors as { [key: string]: string };
    return themeColors[color] ?? color;
};

const BASIC_STYLED = css<WrapperStyledProps>`

    ${({padding}) => padding && css`
        padding: ${padding};
    `};

    ${({paddingTop}) => paddingTop && css`
        padding-top: ${paddingTop};
    `};

    ${({paddingBottom}) => paddingBottom && css` 
        padding-bottom: ${paddingBottom};
    `};

    ${({paddingLeft}) => paddingLeft && css` 
        padding-left: ${paddingLeft};
    `};

    ${({paddingRight}) => paddingRight && css` 
        padding-right: ${paddingRight};
    `};

    ${({margin}) => margin && css` 
        margin: ${margin};
    `};

    ${({marginTop}) => marginTop && css` 
        margin-top: ${marginTop};
    `};

    ${({marginBottom}) => marginBottom && css` 
        margin-bottom: ${marginBottom};
    `};

    ${({marginLeft}) => marginLeft && css` 
        margin-left: ${marginLeft};
    `};

    ${({marginRight}) => marginRight && css` 
        margin-right: ${marginRight};
    `};

    ${({backgroundColor: background, theme}) => background && theme && css` 
        background: ${ ({theme}) => handleBackgroundWrapper(theme, background)};
    `};

    ${({shadow, theme}) => shadow && theme && css` 
        box-shadow: ${shadow === true ? theme.shadow.medium : handleShadowWrapper(theme, shadow)};
    `};

    ${({float}) => float && css`
        float: ${handleFloatWrapper(float)};
    `};

    ${({width}) => width && css`
        width: ${width};
    `};

    ${({height}) => height && css`
        height: ${height};
    `};

    ${({relative}) => relative && css`
        position: relative;
    `};

    ${({bgImage}) => bgImage && css`
        background-image: ${`url(${bgImage})`};
    `};

    ${({bgCover}) => bgCover && css`
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    `};

    ${({borderRadius}) => borderRadius && css`
        border-radius: ${ ({theme}) => borderRadius !== true ? borderRadius : theme.borderRadius};
    `};

    ${({position}) => position && css`
        position: ${position};
    `};

    ${({maxWidth}) => maxWidth && css`
        max-width: ${maxWidth};
    `};

    ${({color}) => color && css`
        color: ${color};
    `};
`;

export const Wrapper = styled.div<WrapperStyledProps>`
    ${BASIC_STYLED};
    ${FLEX_PROPS};
    box-sizing: border-box;
`;