import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import SectionNewsletter from '../components/SectionNewsletter/SectionNewsletter';
import SectionProducts from '../components/SectionProducts/SectionProducts';
import SectionSkincare from '../components/SectionSkincare/SectionSkincare';
import { Col, Container, Row } from '../components/ui/GridSystem/GridSystem';
import { Wrapper } from '../components/ui/Wrapper/Wrapper';
import { Text } from '../components/ui/Typography/Typography';
import Constants from '../config/Constants';
import { getLocalImage } from '../helpers/getLocalImages';
import { ProductDTO } from '../Implementation/DTOs/ProductsDTO';
import { Product } from '../Implementation/Models/Product';
import { Title } from '../components/ui/Title/Title';
import { CartActionType } from '../hooks/reducers/CartReducers';
import { GlobalContext } from '../hooks/context/Context';
import LinkButton from '../components/LinkButton/LinkButton';

const WrapperFullImage = styled(Wrapper)`
    overflow: hidden;
    position: relative;
    &:after, &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: ${({theme}) => theme.colors.main};
        opacity: .3;
    }

    img {
        max-width: initial;
        height: 80vh;
    } 

    @media ${({theme}) => theme.mediaQueries.breakpointLg} {
        img {
            width: auto;
            min-height: 130%;
        }
    }
`;

const WrapperDescription = styled(Wrapper)`
    width: 100%;
    position: relative;
    float: left;
    img {
        z-index: 1;
    }
    &:after, &::after {
        content: '';
        width: 50%;
        height: 100%;
        position: absolute;
        background: ${({theme}) => theme.colors.secondary};
        left: 0;
        top: 0;
        z-index: 0;
    }
    @media ${({theme}) => theme.mediaQueries.breakpointLg} {
        &:after, &::after {
            width: 100%;
        }
    }
`;

const BoxDescrition = styled(Wrapper)`
    color: #fff;
    position: relative;
    z-index: 1;
`;

const UL = styled('ul')`
    padding: 0;
    width: 100%;
    list-style-type: none;
    padding-bottom: 20px;
    li {
        float: left;
        margin-right: 30px;
        cursor: pointer;
        font-size: 13px;
        position: relative;
        &:last-child {
            margin-right: 0px;
        }
        &:after, &::after {
            content: '';
            width: 0px;
            height: 2px;
            transition: all 200ms ease-in-out;
            background: #fff;
            position: absolute;
            left: 0;
            top: 120%;
        }
        &:hover {
            &:after, &::after {
                width: 70px;
            }    
        }
        &.active {
            &:hover {
                &:after, &::after {
                    width: 70px;
                }    
            }
            &:after, &::after {
                width: 20px;
                background: ${({theme}) => theme.colors.main};
            }
        }
    }
`;

const WrapperImage = styled(Wrapper)`
    img { max-width: 250px }
`;

const WrapperButton = styled(Wrapper)`
    background: ${({theme}) => theme.colors.secondary};
    padding: 14px;
    width: fit-content;
    border-radius: 30px;
    cursor: pointer;
    transition: all 300ms ease-in-out;

    ${Text} {
        display: none;
    }

    img {
        max-width: 18px;
        margin-top: -3px;
    }

    &:hover {
        padding: 14px 20px;
        ${Text} {
            display: block;
        }
    }
`;

const WrapperButtonEsaurito = styled(Wrapper)`
    padding: 10px 0px;
    color: ${({theme}) => theme.colors.error};
`;

const WrapperCounter = styled(Wrapper)`
    img {
        cursor: pointer;
    }
`;

enum StateBoxDescription {
    HOW_TO_USE = 'HOW_TO_USE',
    DESCRIPTION = 'DESCRIPTION',
    INCI = 'INCI',
    INGREDIENTI = 'INGREDIENTI'
}

const PageProduct = (props: any) => {
    const refParallax = useRef<HTMLImageElement>(null);
    const context = useContext(GlobalContext)!;
    const [product, setProduct] = useState<Product>(Product.createEmpty());
    const [stateDescription, setstateDescription] = useState(product.howToUse ? StateBoxDescription.HOW_TO_USE : StateBoxDescription.DESCRIPTION);

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        window.addEventListener('scroll', () => handleParallax());
        getProduct();
        setstateDescription(product.howToUse ? StateBoxDescription.HOW_TO_USE : StateBoxDescription.DESCRIPTION);
        return () => {
            window.removeEventListener('scroll', () => {});
        }
    // eslint-disable-next-line
    }, [props.location.pathname, product.id]);


    return (
        <Wrapper marginTop={'100px'} backgroundColor={'#fff'}>
            {renderHero()}
            {renderFormat()}
            {renderContainerDescription()}
            {renderFullImageParallax()}
            {renderSectionProduct()}
            <SectionSkincare/>
            <SectionNewsletter/>
        </Wrapper>
    );

    function renderHero(){
        return (
            <Wrapper width={'100%'} padding={'40px 0px'}>
                <Container>
                    <Row>
                        {renderColumnImageHero()}
                        {renderColumnTextHero()}
                    </Row>
                </Container>
            </Wrapper>
        );
    }

    function renderColumnImageHero(){
        return (
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img style={{maxWidth: '100%'}} src={getLocalImage(product.img)} alt={'product img'} />
            </Col>
        );
    }

    function renderColumnTextHero(){
        return (
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <Wrapper height={'100%'} flex direction={'column'} align={'flex-start'} justify={'center'}>
                    <Title margin='0px 0px 10px 0px'>{product.title}</Title>
                    <Text>{product.description}</Text>
                    {renderCounter()}
                    {renderPriceBox()}
                    {renderBoxButton()}
                </Wrapper>
            </Col>
        );
    }

    function renderCounter(){
        const counter = context.cart.state.products.filter(x => x.id === product.id).length;
        if(product.isPurchasable) return (
            <WrapperCounter margin='40px 0px 20px 0px' flex>
                <img onClick={() => handleRemoveProductInCart()}
                    width={'20px'} src={getLocalImage('icon-remove.svg')} alt={'remove prod'}/>
                <Wrapper margin='0px 10px'>{counter}</Wrapper>
                <img onClick={() => handleAddProductInCart()}
                    width={'20px'} src={getLocalImage('icon-add.svg')} alt={'add prod'}/>
            </WrapperCounter>
        );
        else return null;
    }

    function renderPriceBox(){
        return (
            <Wrapper margin={'20px 0px'}>
                <Text size={'2.5em'} secondary>{'€ ' + product.price.toLocaleString('it-It', { style: 'currency', currency: 'EUR' }).replace('€', '')}</Text>
            </Wrapper>
        );
    }

    function renderBoxButton(){
        if (product.isPurchasable) return (
            <Wrapper margin={'20px 0px'} flex>
                {renderButtonAddCart()}
                {renderCheckoutButton()}
            </Wrapper>
        );
        else return (
            <WrapperButtonEsaurito>
                ESAURITO
            </WrapperButtonEsaurito>
        );
    }

    function renderButtonAddCart(){
        return(
            <WrapperButton
                id={'ayq-btn_cart_product'}
                marginRight={'20px'} flex align={'center'} justify={'center'} onClick={() => handleAddProductInCart()}>
                <Text white margin={'0px 15px 0px 0px'}>Add Cart</Text>
                <img src={getLocalImage('icon-cart.svg')} alt="cart"/>
            </WrapperButton>
        );
    }

    function renderCheckoutButton(){
        return (
            <LinkButton
                id={'ayq-checkout_product'}
                text={Constants.CTA_GO_TO_CHECKOUT}
                url={handleLinkCheckout()}
                targetBlank
                externalLink
                isButton/>
        );
    }

    function renderFormat(){
        return (
            <Wrapper width={'fit-content'} padding={'20px 60px'} backgroundColor='main'>
                <Text white>Formato {product.format}</Text>
            </Wrapper>
        );
    }

    function renderContainerDescription(){
        return (
            <WrapperDescription>
                <Container>
                    <Row>
                        {renderColumnDescription()}
                        {renderColumnImage()}
                    </Row>
                </Container>
            </WrapperDescription>
        );
    }

    function renderColumnDescription(){
        return (
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <BoxDescrition width={'100%'} float={'left'} padding={'80px 0px'} backgroundColor={'secondary'}>
                    {renderHeaderNavBox()}
                    {renderTextBox()}
                </BoxDescrition>
            </Col>
        );
    }

    function renderColumnImage(){
        return (
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <WrapperImage flex align='center' justify={'center'}>
                    <img src={getLocalImage(product.imgHover)} alt={'product img'} />
                </WrapperImage>
            </Col>
        );
    }

    function renderHeaderNavBox(){
        return (
            <UL>
                {product.howToUse && <li
                    className={stateDescription === StateBoxDescription.HOW_TO_USE ? 'active' : ''}
                    onClick={() => setstateDescription(StateBoxDescription.HOW_TO_USE)}>
                        COME SI USA</li>}
                {product.descriptionLong && <li
                    className={stateDescription === StateBoxDescription.DESCRIPTION ? 'active' : ''}
                    onClick={() => setstateDescription(StateBoxDescription.DESCRIPTION)}>
                        DESCRIZIONE</li>}
                {(product.inci || product.ingredienti) && <li
                    className={stateDescription === (product.inci ? StateBoxDescription.INCI : StateBoxDescription.INGREDIENTI) ? 'active' : ''}
                    onClick={() => setstateDescription(product.inci ? StateBoxDescription.INCI : StateBoxDescription.INGREDIENTI)}>
                        {product.ingredienti ? 'INGREDIENTI' : 'INCI'}</li>}
            </UL>
        );
    }

    function renderTextBox(){
        return (
            <Text white large margin={'40px 40px 0px 0px'}>
                {renderContentTextBox()}
            </Text>
        );
    }

    function renderContentTextBox(){
        switch (stateDescription) {
            case StateBoxDescription.HOW_TO_USE:
                return product.howToUse;
            case StateBoxDescription.DESCRIPTION:
                return product.descriptionLong;
            case StateBoxDescription.INCI:
                return product.inci;
            case StateBoxDescription.INGREDIENTI:
                return product.ingredienti;
            default:
                return product.howToUse;
        }
    }

    function renderSectionProduct(){
        return (
            <Wrapper margin='60px 0px'>
                <SectionProducts/>
            </Wrapper>
        );
    }

    function renderFullImageParallax(){
        return (
            <WrapperFullImage width={'100%'} height={'60vh'} flex align={'center'} justify={'center'}>
                <img ref={refParallax} src={getLocalImage('bg-section-girls.png')} alt='okne-products'/>
            </WrapperFullImage>
        );
    }

    async function getProduct(){
        const currentPath = window.location.pathname;
        const products: ProductDTO[] = Constants.PRODUCTS;
        const fiteredProduct = await products.filter(x => `/${x.path}` === currentPath)[0];
        const product = Product.createFromDTO(fiteredProduct)
        setProduct(product);
    }

    function handleParallax(){
        if (!refParallax || !refParallax.current) return null;
        const elm = refParallax.current.getBoundingClientRect();
        const distance = window.innerHeight - elm!.top;
        const translateY = `translateY(${distance / 10}px)`;
        refParallax.current.style.transform = translateY;
    }

    function handleAddProductInCart(){
        context.cart.cartDispatch({
            type: CartActionType.ADD_ITEMS_CART,
            payload: product
        });
    }

    function handleRemoveProductInCart(){
        context.cart.cartDispatch({
            type: CartActionType.REMOVE_ITEMS_CART,
            payload: product
        });
    }

    function handleLinkCheckout(){
        const arrayUniqueByID = [...new Map(context.cart.state.products.map(item => [item.id, item])).values()].map(x => x.id);
        const buildIDandCount = arrayUniqueByID.map(x => {
            return `${x}:${context.cart.state.products.filter(e => e.id === x).length}`
        })
        if (context.cart.state.products.length > 0) return `${Constants.LINK_CHECKOUT_SHOPIFY}${buildIDandCount.join()}`;
        else return `${Constants.LINK_CHECKOUT_SHOPIFY}${product.id}:1`;
    }
}

export default PageProduct;
