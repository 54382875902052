import React, { useState } from 'react';
import { Wrapper } from '../ui/Wrapper/Wrapper';
import { Container, Row, Col } from '../ui/GridSystem/GridSystem';
import styled, { keyframes } from 'styled-components';
import Constants from '../../config/Constants';
import { TitleH3 } from '../ui/Title/Title';
import { getLocalImage } from '../../helpers/getLocalImages';
import { Text } from '../ui/Typography/Typography';
import ReactPlayer from 'react-player';

const animationLoopCircle = keyframes`
    100% {
        transform: rotate(360deg);
    }
`;

const WrapperSection = styled(Wrapper)`
    background: linear-gradient(90deg, rgba(255,255,255,1) 35%, rgba(125,174,211,1) 35%);
    padding: 100px 0px;

    @media ${({theme}) => theme.mediaQueries.breakpointLg} {
        background: ${({theme}) => theme.colors.secondary};
    }
`;

const BoxVideo = styled(Wrapper)`
    display: flex;
    align-items: center;
    justify-content: center;
        img {
            max-width: 200px;
            position: absolute;
            animation: ${animationLoopCircle} 12s linear infinite;
            pointer-events: none;
        }
`;

const SectionSkincare = () => {
    const [showButton, setshowButton] = useState<boolean>(true);
    
    return (
        <WrapperSection>
            <Container>
                <Row>
                    {renderImage()}
                    {renderColumnText()}
                </Row>
            </Container>
        </WrapperSection>
    );

    function renderImage() {
        return (
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <BoxVideo>
                    <ReactPlayer
                        onClickPreview={() => setshowButton(!showButton)}
                        className='react-player'
                        url={getLocalImage('okne-fasi.mp4')}
                        loop
                        playing
                        height='400px'
                        controls
                        light={getLocalImage('okne-skincare.png')}/>
                    {
                        showButton ?
                            <img src={getLocalImage('okne-playme.svg')} alt={'Play Me'} />
                        : null
                    }
                </BoxVideo>
            </Col>
        )
    }

    function renderColumnText() {
        return (
            <Col xl={6} lg={6} md={6} sm={12} xs={12} align={'center'}>
                <TitleH3 white margin='30px 0px 20px 0px'>{Constants.SECTION_SKINCARE_TITLE}</TitleH3>
                <Text large primary margin='0px 0px 40px 0px'>
                    {Constants.SECTION_SKINCARE_TEXT}
                </Text>
            </Col>
        )
    }
    
};

export default SectionSkincare;
