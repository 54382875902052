import React from 'react';
import { Container, Col, Row } from '../ui/GridSystem/GridSystem';
import styled from 'styled-components';
import { Text } from '../ui/Typography/Typography';
import { Wrapper } from '../ui/Wrapper/Wrapper';
import { getLocalImage } from '../../helpers/getLocalImages';
import Constants from '../../config/Constants';

const WrapperSection = styled(Wrapper)`
    padding: 250px 0px;
    @media ${({theme}) => theme.mediaQueries.breakpointLg} {
        padding: 40px 0px;
    }
`;

const BoxImage = styled(Wrapper)`
    max-width: 650px;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -210px;

    @media ${({theme}) => theme.mediaQueries.breakpointLg} {
        width: 100%;
        max-width: 100%;
        position: relative;
        margin-top: 40px;
    }
`;

const SectionBrand = () => {
    return (
        <WrapperSection width={'100%'} position={'relative'}>
            <Container>
                <Row>
                    {renderColumnText()}
                </Row>
            </Container>
            {renderImage()}
        </WrapperSection>
    );

    function renderColumnText() {
        return (
            <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                <Text small margin={'0px 0px 20px 0px'}>{Constants.SECTION_BRAND_TITLE}</Text>
                <Text large primary>
                    {Constants.SECTION_BRAND_TEXT}
                </Text>
            </Col>
        )
    }
    
    function renderImage() {
        return (
            <BoxImage flex justify={'center'} align={'center'}>
                <img src={getLocalImage('okne-products.png')} alt={'Okne brand'} />
            </BoxImage>
        )
    }
};

export default SectionBrand;