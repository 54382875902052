import styled, {css, CSSObject, CSSProp} from 'styled-components';

const GRID_COLUMNS = 12;
const GRID_DIMENSION_XS = 40;
const GRID_DIMENSION_SM = 60;
const GRID_DIMENSION_MD = 90;
const GRID_DIMENSION_LG = 120;
const GRID_DIMENSION_GUTTER = 1;

type GridProperty = 'gridColumns' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const GRID_PROPERTIES: {[key in GridProperty]: number} = {
    gridColumns: GRID_COLUMNS,
    xs: GRID_DIMENSION_XS + GRID_DIMENSION_GUTTER, //rem
    sm: GRID_DIMENSION_SM + GRID_DIMENSION_GUTTER, //rem
    md: GRID_DIMENSION_MD + GRID_DIMENSION_GUTTER, //rem
    lg: GRID_DIMENSION_LG + GRID_DIMENSION_GUTTER, //rem
    xl: GRID_DIMENSION_LG + GRID_DIMENSION_GUTTER // rem
};

type MediaQueryProperty = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const GRID_MEDIA_QUERIES: {[key in MediaQueryProperty]: string} = {
    xs: `only screen and (max-width: ${GRID_PROPERTIES.xs}rem)`,
    sm: `only screen and (min-width: ${GRID_PROPERTIES.xs}rem) and (max-width: ${GRID_PROPERTIES.sm}rem)`,
    md: `only screen and (min-width: ${GRID_PROPERTIES.sm}rem) and (max-width: ${GRID_PROPERTIES.md}rem)`,
    lg: `only screen and (min-width: ${GRID_PROPERTIES.md}rem) and (max-width: ${GRID_PROPERTIES.lg}rem)`,
    xl: `only screen and (min-width: ${GRID_PROPERTIES.xl}rem)`
};

export interface GridStyledProps {
    size?: number
    xl?: number
    lg?: number
    md?: number
    sm?: number
    xs?: number
    show?: true | MediaQueryProperty
    hide?: true | MediaQueryProperty
    reverse?: true | MediaQueryProperty
    column?: true | MediaQueryProperty
    first?: true | MediaQueryProperty
    last?: true | MediaQueryProperty
    align?: 'start' | 'end' | 'center' | 'baseline' | 'stretch'
    offset?: {where: true | Array<MediaQueryProperty>, value: number}
    justify?: 'start' | 'end' | 'center' | 'between' | 'around'
}

const handleStyleContainterMediaQuery = (mediaQuery: string, width: number) => css`
    @media ${mediaQuery}{
        max-width: ${width}rem;
    };
`;

const createMediaQuery = (mediaQuery: string, style: CSSProp | CSSObject) => css`
    @media ${mediaQuery} { 
        ${style};
    };
`;

const setCSSForAllMediaQueries = (style: CSSProp | CSSObject) => css`
    @media ${GRID_MEDIA_QUERIES.xs} {
        ${style};}
    @media ${GRID_MEDIA_QUERIES.sm} {
        ${style};}
    @media ${GRID_MEDIA_QUERIES.md} {
        ${style};}
    @media ${GRID_MEDIA_QUERIES.lg} {
        ${style};}
    @media ${GRID_MEDIA_QUERIES.xl} {
        ${style};}
`;

const handleDisplayMediaQuery = (type: MediaQueryProperty, display: ('flex' | 'none')) => css`
    ${createMediaQuery(
        GRID_MEDIA_QUERIES[type], { display: display }
    )}
`;

const PROPS_GRID_SYSTEM = css<GridStyledProps>`

    ${({show}) => show && css`
        ${ show === true ?
        setCSSForAllMediaQueries({'display': 'flex'})
        : handleDisplayMediaQuery(show, 'flex')}
    `};

    ${({hide}) => hide && css`
        ${ hide === true ?
        setCSSForAllMediaQueries({'display': 'none'})
        : handleDisplayMediaQuery(hide, 'none')}
    `};

    ${({first}) => first && css`
        ${ first === true ?
        setCSSForAllMediaQueries({order: -1})
        : createMediaQuery(GRID_MEDIA_QUERIES[first], {order: -1})}
    `};

    ${({last}) => last && css`
        ${ last === true ?
        setCSSForAllMediaQueries({order: 1})
        : createMediaQuery(GRID_MEDIA_QUERIES[last], {order: 1})}
    `};

    ${({align}) => align && css`
        align-self: ${ align === ('end' || 'start') ? `flex-${align}` : align};
    `}

    ${({justify}) => justify && css`
        justify-content: ${ justify === ('end' || 'start') ? `flex-${justify}` :
        justify === ('around' || 'between') ? `space-${justify}` : justify };
    `}

`;

const SPACE_SMALL_MEDIA_QUERY = 12;
const SPACE_MEDIUM_MEDIA_QUERY = 20;
const SPACE_LARGE_MEDIA_QUERY = 30;
const PERCENTAGE = 100;
export const Container = styled.div<GridStyledProps>`
    ${PROPS_GRID_SYSTEM}
    width: 100%;
    max-width: ${GRID_DIMENSION_LG}rem;
    padding: 0 ${GRID_DIMENSION_GUTTER}rem;
    margin: 0 auto;
    ${handleStyleContainterMediaQuery(GRID_MEDIA_QUERIES.xs, Number(GRID_PROPERTIES.xs - SPACE_SMALL_MEDIA_QUERY))}
    ${handleStyleContainterMediaQuery(GRID_MEDIA_QUERIES.sm, Number(GRID_PROPERTIES.sm - SPACE_MEDIUM_MEDIA_QUERY))}
    ${handleStyleContainterMediaQuery(GRID_MEDIA_QUERIES.md, Number(GRID_PROPERTIES.md - SPACE_LARGE_MEDIA_QUERY))}
    ${handleStyleContainterMediaQuery(GRID_MEDIA_QUERIES.lg, Number(GRID_PROPERTIES.lg - SPACE_LARGE_MEDIA_QUERY))}
    ${handleStyleContainterMediaQuery(GRID_MEDIA_QUERIES.lg, Number(GRID_PROPERTIES.xl - SPACE_LARGE_MEDIA_QUERY))}
`;

export const Row = styled.div<GridStyledProps>`
    ${PROPS_GRID_SYSTEM}
    box-sizing: border-box;
    margin: 0 auto;
    width: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;

    ${({reverse}) => reverse && css`
        ${ reverse === true ?
        setCSSForAllMediaQueries({'flex-direction': 'row-reverse;'})
        : createMediaQuery(GRID_MEDIA_QUERIES[reverse], {'flex-direction': 'row-reverse;'})}
    `};

    ${({column}) => column && css`
        ${ column === true ?
        setCSSForAllMediaQueries({'flex-direction': 'column;'})
        : createMediaQuery(GRID_MEDIA_QUERIES[column], {'flex-direction': 'column;'})}
    `};

`;

const CALC_WIDTH_COLUMN = (number: number) => PERCENTAGE / GRID_COLUMNS * number;

const PROP_COLUMN = (number: number) => css`
        box-sizing: border-box;
        flex-basis: ${CALC_WIDTH_COLUMN(number)}%;
        max-width:  ${CALC_WIDTH_COLUMN(number)}%;
        padding: 0 ${GRID_DIMENSION_GUTTER}rem;
`;

export const Col = styled.div<GridStyledProps>`
    ${PROPS_GRID_SYSTEM}
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 0 1rem;

    ${({reverse}) => reverse && css`
        ${ reverse === true ?
        setCSSForAllMediaQueries({'flex-direction': 'column-reverse;'})
        : createMediaQuery(GRID_MEDIA_QUERIES[reverse], {'flex-direction': 'column-reverse;'})}
    `};

    ${({size}) => size && css`
        ${PROP_COLUMN(size)}
    `}

    ${({xs}) => xs && css`
        ${createMediaQuery(GRID_MEDIA_QUERIES.xs, PROP_COLUMN(xs))}
    `}

    ${({sm}) => sm && css`
        ${createMediaQuery(GRID_MEDIA_QUERIES.sm, PROP_COLUMN(sm))}
    `}

    ${({md}) => md && css`
        ${createMediaQuery(GRID_MEDIA_QUERIES.md, PROP_COLUMN(md))}
    `}

    ${({lg}) => lg && css`
        ${createMediaQuery(GRID_MEDIA_QUERIES.lg, PROP_COLUMN(lg))}
    `}

    ${({xl}) => xl && css`
        ${createMediaQuery(GRID_MEDIA_QUERIES.xl, PROP_COLUMN(xl))}
    `}

    ${({offset}) => offset && css`
        ${ offset.where === true ?
        setCSSForAllMediaQueries({'margin-left': `${CALC_WIDTH_COLUMN(offset.value)}%`})
        : offset.where.map(x => {
            return css`
                @media ${GRID_MEDIA_QUERIES[x]} {
                    margin-left: ${CALC_WIDTH_COLUMN(offset.value)}%;
                };
            `;
        })}
    `};
`;