import React from 'react'
import styled, { keyframes } from 'styled-components';
import Constants from '../../config/Constants';
import { Wrapper } from '../ui/Wrapper/Wrapper'

function TextLoop() {

    if (!isAvailable()) return null;
    else return (
        <WrapperTextLoop flex>
            {renderRowWrapperSpan()}
            {renderRowWrapperSpan()}
        </WrapperTextLoop>
    );

    function renderRowWrapperSpan(){
        const content = new Array(30).fill(Constants.BANNER_LOOP.text).map((x, i) => (
            <Span key={i}>{x} <i>•</i></Span>
        ))
        return <WrapperSpan>{content}</WrapperSpan>
    }

    function isAvailable(): boolean {
        const startDate = new Date(Constants.BANNER_LOOP.date.start)
        const endDate = new Date(Constants.BANNER_LOOP.date.end)
        const today = new Date()
        return today > startDate && today < endDate;
    }
}

export default TextLoop;

const WrapperTextLoop = styled(Wrapper)`
    width: 100%;
    background: ${({theme}) => theme.colors.main};
    padding: 10px 0;
    position: sticky;
    top: 0;
    overflow: hidden;
`;

const anmLoop = keyframes`
    100% {
        transform: translateX(-100%);    
    }
`;

const WrapperSpan = styled(Wrapper)`
    white-space: nowrap;
    display: flex;
    animation: ${anmLoop} 80s linear infinite;
`;

const Span = styled('span')`
    color: #fff;
    font-weight: 400;
    font-size: 15px;
    display: flex;
    align-items: center;

    i {
        color: ${({theme}) => theme.colors.secondary};
        font-size: 22px;
        margin: 0;
        display: inline-block;
        margin: 0 10px;
    }
`;