import * as React from 'react';
import { Cart } from '../../Implementation/Models/Cart';

export enum CartActionType {
    ADD_ITEMS_CART = 'ADD_ITEMS_CART',
    REMOVE_ITEMS_CART = 'REMOVE_ITEMS_CART'
}


export interface ICartReducer {
    type: CartActionType,
    payload?: any
}

export interface CartState {
    products: Cart[]
}

export const initialStateCart: CartState = {
    products: []
}

const CartReducer: React.Reducer<CartState, ICartReducer> = (state, action) => {
    switch (action.type) {
        case CartActionType.ADD_ITEMS_CART:
            return { 
                ...state,
                products: [...state.products, action.payload]
            }
        case CartActionType.REMOVE_ITEMS_CART:
            return { 
                ...state,
                products: state.products.filter(x => x.id === action.payload.id).slice(0, -1)
                    .concat(state.products.filter(x => x.id !== action.payload.id))
            }
    }
}

export default CartReducer;