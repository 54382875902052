import { ProductDTO } from "../DTOs/ProductsDTO";

export interface ProductValues {
    id: number
    path: string
    img: string
    imgHover: string
    price: number
    title: string
    description: string
    howToUse?: string
    descriptionLong?: string
    inci?: string
    ingredienti?: string
    format?: string
    isPurchasable: boolean
}

export class Product {
    private readonly values: ProductValues;

    constructor(values: ProductValues) {
        this.values = values;
    }

    public static createFromDTO(dto: ProductDTO){
        let values: ProductDTO = {
            id: dto.id,
            path: dto.path,
            img: dto.img,
            imgHover: dto.imgHover,
            price: dto.price,
            title: dto.title,
            description: dto.description,
            howToUse: dto.howToUse,
            descriptionLong: dto.descriptionLong,
            format: dto.format,
            isPurchasable: dto.isPurchasable
        }
        if (dto.inci) values.inci = dto.inci
        if (dto.ingredienti) values.ingredienti = dto.ingredienti
        return new Product(values);
    }

    public static createEmpty(){
        return new Product ({
            id: 0,
            path: '',
            img: '',
            imgHover: '',
            price: 0,
            title: '',
            description: '',
            howToUse: '',
            descriptionLong: '',
            format: '',
            isPurchasable: false
        });
    }

    public get id(){
        return this.values.id;
    }

    public get path(){
        return this.values.path;
    }

    public get img(){
        return this.values.img;
    }

    public get imgHover(){
        return this.values.imgHover;
    }

    public get price(){
        return this.values.price;
    }

    public get title(){
        return this.values.title;
    }

    public get description(){
        return this.values.description;
    }

    public get howToUse(){
        return this.values.howToUse;
    }

    public get descriptionLong(){
        return this.values.descriptionLong;
    }

    public get inci(){
        return this.values.inci;
    }

    public get ingredienti(){
        return this.values.ingredienti;
    }

    public get format(){
        return this.values.format;
    }

    public get isPurchasable(){
        return this.values.isPurchasable;
    }

}