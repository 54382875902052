import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getLocalImage } from '../../helpers/getLocalImages';
import CartHead from '../CartHead/CartHead';
import TextLoop from '../TextLoop/TextLoop';
import { Wrapper } from '../ui/Wrapper/Wrapper';

const WrapperFunctionally = styled(Wrapper)`
`;
const WrapperHeader = styled(Wrapper)`
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 100;
`;

const HeaderStyled = styled('header')`
    width: 100%;
    height: 100px;
    padding: 10px 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${({theme}) => theme.colors.secondary};
`;

const Logo = styled(Wrapper)`
    img {
        max-width: 70px;
    }
`;

function Header() {
    return (
        <WrapperHeader>
            <TextLoop/>
            <HeaderStyled>
                {renderLogo()}
                {renderMenuAndCart()}
            </HeaderStyled>
        </WrapperHeader>
    );

    function renderMenuAndCart() {
        return (
            <WrapperFunctionally flex align={'center'}>
                <CartHead/>
            </WrapperFunctionally>
        );
    }

    function renderLogo(){
        return (
            <Logo maxWidth={'90px'}>
                <Link to={'./'}>
                    <img src={getLocalImage('okne-logo.png')} alt={'Logo Okne'} />
                </Link>
            </Logo>
        );
    }
}

export default Header; 

