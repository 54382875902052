import React, { useState } from 'react';
import { Container, Row, Col } from '../ui/GridSystem/GridSystem';
import { Wrapper } from '../ui/Wrapper/Wrapper';
import styled from 'styled-components';
import { getLocalImage } from '../../helpers/getLocalImages';
import Constants from '../../config/Constants';
import { Text } from '../ui/Typography/Typography';
import validator from 'validator';


const WrapperNewsLetter = styled(Wrapper)`
    width: 100%;
    position: relative;
    margin-top: 120px;
    padding: 60px 0 0 0;
    background: #D7CFCA;

    img {
        max-width: 100%;
        margin: 0 0 -7px 0;
        
        @media ${({theme}) => theme.mediaQueries.breakpointMd} {
            margin-top: 80px;
        }
    }

    input {
        margin: 40px 10% 20px 0;
        width: 100%;
        max-width: 300px;
        background: none;
        border: 2px solid ${({theme}) => theme.colors.main};
        padding: 15px;
        outline: none;
        transition: all 200ms ease-in-out;
        &::placeholder {
            color: ${({theme}) => theme.colors.main};
        }
        &:focus {
            border-bottom: 2px solid ${({theme}) => theme.colors.secondary};
            border-top: none;
            border-left: none;
            border-right: none;
            font-size: 1.3em;
        }
    }

    button {
        width: 150px;
        padding: 15px;
        border-radius: 50px;
        background: transparent;
        cursor: pointer;
        transition: all 200ms ease-in-out;
        border: 2px solid ${({theme}) => theme.colors.main};
        &:hover {
            background: ${({theme}) => theme.colors.main};
            color: #fff;
        }
    }
`;


const WrapperCheck = styled(Wrapper)`
    font-size: 10px;
    margin: 10px 0;
    a {
        color: ${({theme}) => theme.colors.main};
    }
    input {
        margin: 0px!important;
        width: 20px;
    }
`;

const SectionNewsletter = () => {

    const [mail, setmail] = useState<string>('');
    const [check, setcheck] = useState<boolean>(false);
    const [errorMail, setErrorMail] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [validMessage, setValidMessage] = useState<boolean>(false);

    return (
        <WrapperNewsLetter>
            <Container>
                <Row>
                    {renderColumnForm()}
                    {renderColumnImage()}
                </Row>
            </Container>
        </WrapperNewsLetter>
    )


    function renderColumnForm() {
        return (
            <Col xl={7} lg={7} md={7} sm={12} xs={12}>
                <Text size='30px' margin='20px 5px' weight='600' dangerouslySetInnerHTML={{__html: Constants.SECTION_NEWSLETTER_TITLE}} />
                <input type="text" placeholder="Il tuo indirizzo email..." onChange={(e) => setmail(e.target.value)} />
                <button onClick={() => handleNewsLetter()}>Subscribe</button>
                <WrapperCheck width='100%'>
                    <input  className='check' width={'20px'} type="checkbox" name="check" onClick={() => setcheck(!check)}/>
                    Desidero ricevere aggiornamenti su prodotti e iniziative di marketing, per cui presto il mio consenso al trattamento dei miei dati personali, secondo l'<a href="https://www.iubenda.com/privacy-policy/37144746" rel="noreferrer" target="_blank">informativa della privacy.</a>
                </WrapperCheck>
                {renderErrorMessage()}
                {renderValidMessage()}
            </Col>
        )
    }

    function renderErrorMessage(){
        if (errorMail) return (
            <Text error margin='0 0 20px 0'>
                {errorMessage ? errorMessage : 'Inserisci una mail vailda'}
            </Text>
        );
        else return null;
    }

    function renderValidMessage(){
        if (validMessage) return (
            <Text primary margin='0 0 20px 0'>
                Grazie, registrazione completata
            </Text>
        );
        else return null;
    }

    function renderColumnImage() {
        return (
            <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                <img src={getLocalImage('letter-item.png')} alt={'Okne Newsletter'} />
            </Col>
        )
    }

    async function handleNewsLetter() {
        setErrorMail(false)
        setErrorMessage(null)
        if (!validator.isEmail(mail)) return setErrorMail(true);
        if (!!!check) {
            setErrorMail(true);
            return setErrorMessage('Accetta termini condizioni');
        }
        else {
            setErrorMail(false);
            try {
                const url = 'https://hooks.zapier.com/hooks/catch/3939523/bmn2qbr/'
                const requestOptions = {
                    method: 'POST',
                    body: JSON.stringify({ email: mail })
                };
                await fetch(url, requestOptions );
                setValidMessage(true)
            } catch (error) {
                console.log(false)
            }
        }
    }

}
export default SectionNewsletter;
