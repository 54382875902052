import React from 'react'
import styled from 'styled-components';
import SectionBrand from '../components/SectionBrand/SectionBrand';
import SectionFAQ from '../components/SectionFAQ/SectionFAQ';
import SectionProducts from '../components/SectionProducts/SectionProducts';
import SectionSkincare from '../components/SectionSkincare/SectionSkincare';
import SectionNewsletter from '../components/SectionNewsletter/SectionNewsletter';
import { Wrapper } from '../components/ui/Wrapper/Wrapper';
import CarouselHero from '../components/CarouselHero/CarouselHero';

const WrapperPage = styled(Wrapper)`
    width: 100%;
    position: relative;
    margin: 0px;
    background: white;
`;

const PageHome = () => {
    return (
        <WrapperPage>
            <CarouselHero/>
            <SectionProducts/>
            <SectionBrand/>
            <SectionSkincare/>
            <SectionNewsletter/>
            <SectionFAQ/>
        </WrapperPage>
    );
}

export default PageHome;