import React, { useState } from 'react'
import styled from 'styled-components';
import { getLocalImage } from '../../helpers/getLocalImages';
import { Wrapper } from '../ui/Wrapper/Wrapper';

const WrapperFaq = styled(Wrapper)`
    position: relative;
    border-top: 1px solid #fff;
    padding: 30px 10%;
    color: #fff;
    letter-spacing: 0.03em;
    transition: all 200ms ease-in-out;
    cursor: pointer;

    img.active {
        transform: rotate(180deg);
    }
`;

const WrapperContent = styled(Wrapper)`
    line-height: 150%;
    letter-spacing: .01em;
    a {
        color: #fff;
    }
`;

interface FAQProps {
    title: string
    content: string
}

const FAQ: React.FC<FAQProps> = (props) => {
    const [showContent, setshowContent] = useState<boolean>(false);

    return (
        <WrapperFaq flex justify='space-between'
            onClick={() => setshowContent(!showContent)}>
            <span>
                {props.title}
                {renderContent()}
            </span>
            <img className={showContent ? 'active' : ''} src={getLocalImage('arrow-white.svg')} alt={'arrow'} />
        </WrapperFaq>
    );

    function renderContent(){
        if (showContent) return (
            <WrapperContent maxWidth={'600px'} margin={'20px 0px 0px 0px'} color='#ddd'
                dangerouslySetInnerHTML={{__html: props.content}}></WrapperContent>
        );
        else return null;
    }
}

export default FAQ
