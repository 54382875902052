import styled from 'styled-components';
import { Wrapper } from '../Wrapper/Wrapper';

export const IconArrow = styled(Wrapper)`
    overflow: hidden;
    margin-left: 20px;
    &.white {
        span { 
            background: #fff!important;
            &::after, &:after, &::before, &:before {
                background: #fff!important;
            }
        }
    }
    span {
        width: 15px;
        height: 1px;
        background: ${({theme}) => theme.colors.secondary};
        position: absolute;

        &::after, &:after, &::before, &:before {
            content: '';
            width: 4px;
            height: 1px;
            background: ${({theme}) => theme.colors.secondary};
            position: absolute;
            right: 0;
            transform-origin: 100%;
        }
        &::after, &:after {
            transform: rotate(-45deg);
        }
        &::before, &:before {
            transform: rotate(45deg);
        }
    }
`;
