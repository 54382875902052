import styled, {css} from 'styled-components';

interface TipographyStyledProps {
    primary?: boolean
    secondary?: boolean
    dark?: boolean
    grey?: boolean
    white?: boolean
    error?: boolean
    success?: boolean
    warning?: boolean
    color?: string
    size?: string
    left?: boolean,
    center?: boolean,
    right?: boolean,
    align?: 'left' | 'center' | 'right'
    large?: boolean
    medium?: boolean
    small?: boolean
    weight?: '100' | '300' | '400' | '500' | '600' | '700' | '900'
    margin?: string
    padding?: string
}

const BASIC_STYLED = css<TipographyStyledProps>`
    color: ${({theme}) => theme.colors.text};
    line-height: 130%;

    ${({weight}) => weight && css`
        font-weight: ${weight};
    `};

    ${({primary}) => primary && css`
        color: ${({theme}) => theme.colors.text};
    `};

    ${({secondary}) => secondary && css`
        color: ${({theme}) => theme.colors.secondary};
    `};

    ${({dark}) => dark && css`
        color: ${({theme}) => theme.colors.dark};
    `};

    ${({grey}) => grey && css`
        color: ${({theme}) => theme.colors.grey};
    `};

    ${({white}) => white && css`
        color: #fff;
    `};

    ${({error}) => error && css`
        color: ${({theme}) => theme.colors.error};
    `};

    ${({success}) => success && css`
        color: ${({theme}) => theme.colors.success};
    `};

    ${({warning}) => warning && css`
        color: ${({theme}) => theme.colors.warning};
    `};

    ${({color}) => color && css`
        color: ${color};
    `};

    ${({size}) => size && css`
        font-size: ${size}!important;
    `};

    ${({left}) => left && css`
        text-align: left;
    `};

    ${({center}) => center && css`
        text-align: center;
    `};

    ${({right}) => right && css`
        text-align: right;
    `};

    ${({align}) => align && css`
        text-align: ${align};
    `};

    ${({large}) => large && css`
        font-size: ${({theme}) => theme.fontSizes.large}!important;
    `};

    ${({medium}) => medium && css`
        font-size: ${({theme}) => theme.fontSizes.medium}!important;
    `};

    ${({small}) => small && css`
        font-size: ${({theme}) => theme.fontSizes.small}!important;
    `};

    ${({margin}) => margin && css`
        margin: ${margin};
    `};

    ${({padding}) => padding && css`
        padding: ${padding};
    `};
`;

export const Text = styled('p')<TipographyStyledProps>`
    ${BASIC_STYLED};
    font-size: ${props => props.theme.fontSizes.p}
`;