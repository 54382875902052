import React, { useContext, useState } from 'react'
import styled, { keyframes } from 'styled-components';
import Constants from '../../config/Constants';
import { getLocalImage } from '../../helpers/getLocalImages';
import { GlobalContext } from '../../hooks/context/Context';
import { Wrapper } from '../ui/Wrapper/Wrapper';
import { Text } from '../ui/Typography/Typography';
import { Cart } from '../../Implementation/Models/Cart';
import { CartActionType } from '../../hooks/reducers/CartReducers';

const fadeCart = keyframes`
    0% {
        right: -600px;
        transform: rotate(10deg);
    }
`;

const WrapperCart = styled(Wrapper)`
    position: relative;
    clear: both;
    cursor: pointer;

    .circle {
        position: absolute;
        padding: 2px 7px;
        margin-top: -8px;
        margin-left: 11px;
        color: #fff;
        z-index: 1;
        border-radius: 10px;
        background: ${({theme}) => theme.colors.error};
        font-size: 11px;
        pointer-events: none;
    }
`;

const WrapperContent = styled(Wrapper)`
    width: 100vw;
    max-width: 600px;
    height: 100vh;
    position: fixed;
    padding: 100px 40px 0px 40px;
    background: #fff;
    right: 0;
    top: 0px;
    z-index: 1;
    box-shadow: -10px 0px 60px rgba(0,0,0, .1);
    animation: ${fadeCart} 300ms ease-in-out;
    overflow: scroll;
`;

const WrapperItemsCart = styled(Wrapper)`
    img {
        max-width: 100px;
    }
`;

const WrapperFootCard = styled(Wrapper)`
    a {
        width: 100%;
        display: inline-block;
        margin-top: 30px;
        padding: 20px 0px;
        text-align: center;
        border: 1px solid ${({theme}) => theme.colors.secondary};
        background: ${({theme}) => theme.colors.secondary};
        color: #fff;
        border-radius: 30px;
        text-transform: uppercase;
        text-decoration: none;
        transition: all 150ms ease-in-out;
        &:hover{
            background: transparent;
            color: ${({theme}) => theme.colors.secondary};
        }
    }

    .go-back {
        text-decoration: underline;
        transition: all 200ms ease-in-out;
        &:hover {
            transform: translateX(-5px);
            color: ${({theme}) => theme.colors.secondary};
        }
    }
`;

const WrapperIconClose = styled(Wrapper)`
    top: 40px;
    right: 40px;
    color: ${({theme}) => theme.colors.error};
    transition: all 200ms ease-in-out;
    &:hover {
        transform: rotate(-90deg);
    }
`;

const CartHead = () => {
    const context = useContext(GlobalContext)!;
    const [openedCart, setopenedCart] = useState(false);

    return (
        <WrapperCart>
            {
                context.cart.state.products.length > 0 ? 
                    <div className={'circle'}>{context.cart.state.products.length}</div>
                : null
            }
            <img 
                onClick={() => setopenedCart(!openedCart)}
                src={getLocalImage(openedCart ? 'icon-cart-secondary.svg' : 'icon-cart.svg')} alt={'cart'}/>
            {renderContentCart()}
        </WrapperCart>
    );

    function renderContentCart(){
        if (openedCart) return (
            <WrapperContent>
                {renderIconClose()}
                {renderEmptyCart()}
                {renderProductsCart()}
                {renderFootCard()}
            </WrapperContent>
        );
        else return null;
    }

    function renderIconClose(){
        return (
            <WrapperIconClose
                onClick={() => setopenedCart(!openedCart)}
                flex align='center' justify='center' position='absolute' borderRadius='50px' shadow='medium' width='40px' height='40px' backgroundColor='#fff'>
                ✕
            </WrapperIconClose>
        )
    }

    function renderEmptyCart(){
        if (context.cart.state.products.length < 1) return (
            <Wrapper width={'100%'} flex direction={'column'} align={'center'}>
                <img src={getLocalImage('empty-state-cart.png')} alt='empty state'/>
                <Text margin={'20px 0px 10px 0px'} weight={'700'} large>{Constants.CART_EMPTY_TITLE}</Text>
            </Wrapper>
        );
        else return null;
    }

    function renderProductsCart(){
        const arrayUniqueByID = [...new Map(context.cart.state.products.map(item => [item.id, item])).values()];
        return arrayUniqueByID
            .sort((a, b) => a.id - b.id)
            .map(x => itemsCart(x))
    }

    function itemsCart(product: Cart) {
        return (
            <WrapperItemsCart key={product.title} flex justify={'space-between'} align={'center'}>
                <img src={getLocalImage(product.img)} alt={'product cart'}/>
                <Wrapper>
                    <Text>{product.title}</Text>
                    <Text secondary margin={'5px 0px'}>
                        {`€ ${String(product.price).replace('.', ',')}0`}
                    </Text>
                </Wrapper>
                {renderCounter(product)}
            </WrapperItemsCart>
        );
    }

    function renderCounter(product: Cart){
        const counter = context.cart.state.products.filter(x => x.id === product.id);
        return (
            <Wrapper width={'80px'} flex align={'center'}>
                {renderRemoveIcon(product)}
                <Wrapper padding={'0 10px'}>{counter.length}</Wrapper>
                {renderAddIcon(product)}
            </Wrapper>
        );
    }

    function renderRemoveIcon(product: Cart){
        return(
            <Wrapper padding='3px' margin='0' onClick={() => handleRemoveProduct(product)}>
                <img width={'20px'} src={getLocalImage('icon-remove.svg')} alt={'icon btn'}/>
            </Wrapper>
        )
    }

    function renderAddIcon(product: Cart){
        return(
            <Wrapper padding='3px' margin='0' onClick={() => handleAddProduct(product)}>
                <img width={'20px'} src={getLocalImage('icon-add.svg')} alt={'icon btn'}/>
            </Wrapper>
        )
    }

    function renderFootCard(){
        const totalPrices = context.cart.state.products.map(x => x.price).reduce((x, i) => x + i,0);
        return (
            <WrapperFootCard>
                <Wrapper width={'100%'} flex align={'center'} justify='space-between' padding='10px 0px'>
                    <Text grey>Tot.</Text>
                    <Text large>{'€ ' + totalPrices.toLocaleString('it-It', { style: 'currency', currency: 'EUR' }).replace('€', '')}</Text>
                </Wrapper>
                <hr />
                <a id={'ayq-cart_checkout'} href={handleLinkCheckout()}>
                    {Constants.CTA_GO_TO_CHECKOUT}
                </a>
                <Text className='go-back' margin={'20px 0px'} onClick={() => setopenedCart(!openedCart)}>{Constants.CTA_GO_BACK}</Text>
            </WrapperFootCard>
        );
    }

    function handleAddProduct(product: Cart){
        context.cart.cartDispatch({
            type: CartActionType.ADD_ITEMS_CART,
            payload: product
        });
    }

    function handleRemoveProduct(product: Cart){
        context.cart.cartDispatch({
            type: CartActionType.REMOVE_ITEMS_CART,
            payload: product
        });
    }

    function handleLinkCheckout(){
        const arrayUniqueByID = [...new Map(context.cart.state.products.map(item => [item.id, item])).values()].map(x => x.id);
        const buildIDandCount = arrayUniqueByID.map(x => {
            return `${x}:${context.cart.state.products.filter(e => e.id === x).length}`
        })
        return `${Constants.LINK_CHECKOUT_SHOPIFY}${buildIDandCount.join()}`;
    }

}

export default CartHead;
