/* eslint-disable @typescript-eslint/no-magic-numbers */
import styled, {css} from 'styled-components';

interface TitleStyledProps {
    primary?: boolean
    secondary?: boolean
    white?: boolean
    size?: string
    left?: boolean,
    center?: boolean,
    right?: boolean,
    align?: 'left' | 'center' | 'right',
    margin?: string;
    padding?: string;
    weight?: 300 | 400 | 500 | 600 | 800 | 900;
}

const BASIC_STYLED = css<TitleStyledProps>`
    color: ${({ theme: { colors } }) => colors.main};
    font-weight: 700;

    ${({primary}) => primary && css` 
        color: ${({theme}) => theme.colors.main};
    `};

    ${({secondary}) => secondary && css` 
        color: ${props => props.theme.colors.secondary};
    `};

    ${({white}) => white && css`
        color: #fff;
    `};

    ${({left}) => left && css`
        text-align: left;
    `};

    ${({center}) => center && css`
        text-align: center;
    `};

    ${({right}) => right && css`
        text-align: right;
    `};

    ${({align}) => align && css`
        text-align: ${align};
    `};

    ${({margin}) => margin && css`
        margin: ${margin};
    `};

    ${({padding}) => padding && css`
        padding: ${padding};
    `};

    ${({weight}) => weight && css`
        font-weight: ${weight};
    `};
    
`;

export const Title = styled('h1')<TitleStyledProps>`
    ${BASIC_STYLED}
    font-size: ${({theme, size}) => `calc(${theme.fontSizes.h1.mob} +
        (${parseInt(size ?? theme.fontSizes.h1.desk, 10)} - ${parseInt(theme.fontSizes.h1.mob)}) *
        ((100vw - 300px) / (1200 - 300)))
    `};
`;

export const TitleH1 = styled('h1')<TitleStyledProps>`
    ${BASIC_STYLED}
    font-size: ${({theme, size}) => `calc(${theme.fontSizes.h1.mob} +
        (${parseInt(size ?? theme.fontSizes.h1.desk, 10)} - ${parseInt(theme.fontSizes.h1.mob)}) *
        ((100vw - 300px) / (1200 - 300)))
    `};
`;

export const TitleH2 = styled('h2')<TitleStyledProps>`
    ${BASIC_STYLED}
    font-size: ${({theme, size}) => `calc(${theme.fontSizes.h2.mob} +
        (${parseInt(size ?? theme.fontSizes.h2.desk, 10)} - ${parseInt(theme.fontSizes.h2.mob)}) *
        ((100vw - 300px) / (1200 - 300)))
    `};
`;

export const TitleH3 = styled('h3')<TitleStyledProps>`
    ${BASIC_STYLED}
    font-size: ${({theme, size}) => `calc(${theme.fontSizes.h3.mob} +
        (${parseInt(size ?? theme.fontSizes.h3.desk, 10)} - ${parseInt(theme.fontSizes.h3.mob)}) *
        ((100vw - 300px) / (1200 - 300)))
    `};
`;

export const TitleH4 = styled('h4')<TitleStyledProps>`
    ${BASIC_STYLED}
    font-size: ${({theme, size}) => `calc(${theme.fontSizes.h4.mob} +
        (${parseInt(size ?? theme.fontSizes.h4.desk, 10)} - ${parseInt(theme.fontSizes.h4.mob)}) *
        ((100vw - 300px) / (1200 - 300)))
    `};
`;

export const TitleH5 = styled('h5')<TitleStyledProps>`
    ${BASIC_STYLED}
    font-size: ${({theme, size}) => `calc(${theme.fontSizes.h5.mob} +
        (${parseInt(size ?? theme.fontSizes.h5.desk, 10)} - ${parseInt(theme.fontSizes.h5.mob)}) *
        ((100vw - 300px) / (1200 - 300)))
    `};
`;

export const TitleH6 = styled('h6')<TitleStyledProps>`
    ${BASIC_STYLED}
    font-size: ${({theme, size}) => `calc(${theme.fontSizes.h6.mob} +
        (${parseInt(size ?? theme.fontSizes.h6.desk, 10)} - ${parseInt(theme.fontSizes.h6.mob)}) *
        ((100vw - 300px) / (1200 - 300)))
    `};
`;