import React from 'react';
import { Wrapper } from '../ui/Wrapper/Wrapper';
import Constants from '../../config/Constants';
import { Col, Container, Row } from '../ui/GridSystem/GridSystem';
import CardProduct from '../CardProduct/CardProduct';
import { ProductDTO } from '../../Implementation/DTOs/ProductsDTO';

const SectionProducts = () => {
    return (
        <Wrapper width={'100%'} id="s-products">
            <Container>
                <Row>
                    {renderProducts()}
                </Row>
            </Container>
        </Wrapper>
    )

    function renderProducts(){
        const products: ProductDTO[] = Constants.PRODUCTS;
        return products.map((x, i) => {
            return (
                <Col key={i} xl={3} lg={3} md={3} sm={6} xs={12}>
                    <CardProduct
                        id={x.id}
                        path={x.path}
                        img={x.img}
                        imgHover={x.imgHover}
                        title={x.title}
                        price={x.price}
                        description={x.description}
                        isPurchasable={x.isPurchasable}/>
                </Col>
            );
        })
    }
}

export default SectionProducts;
