import React, { useMemo, useReducer } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { PageHome, PageProduct } from './pages';
import { GlobalContext } from './hooks/context/Context';
import { Footer, Header } from './components/navigationals';
import CartReducer, { initialStateCart } from './hooks/reducers/CartReducers';
import ButtonScrollTop from './components/ButtonScrollTop/ButtonScrollTop';

function App() {
	
	const [cartState, cartDispatch] = useReducer(CartReducer, initialStateCart)

	const value = useMemo(() => ({
		cart: { state: cartState, cartDispatch}
	}), [cartState]);
	
	return (
		<GlobalContext.Provider value={value}>
			<BrowserRouter>
				<Header />
				<ButtonScrollTop/>
				<Switch>
					<Route path={'/'} exact component={PageHome} />
					<Route path={'/:productName'} exact component={PageProduct} />
				</Switch>
				<Footer />
			</BrowserRouter>
		</GlobalContext.Provider>
	);
}

export default App;
