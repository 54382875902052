import gsap from 'gsap';
import React, { useState } from 'react'
import styled from 'styled-components';
import Constants from '../../config/Constants';
import { getLocalImage } from '../../helpers/getLocalImages';
import LinkButton from '../LinkButton/LinkButton';
import { Col, Container, Row } from '../ui/GridSystem/GridSystem';
import { TitleH2 } from '../ui/Title/Title';
import { Wrapper } from '../ui/Wrapper/Wrapper'

function CarouselHero() {

    const [page, setPage] = useState<number>(0)
    const isMobile = window.innerWidth < 768;

    return (
        <>
            <WrapperCarousel width='100%'>
                {renderItemsCarousel()}
                {window.innerWidth >= 768 && renderMiniImages()}
                {renderControls()}
            </WrapperCarousel>
            {window.innerWidth <= 768 && renderMiniImages()}
        </>
    );

    function renderItemsCarousel() {
        return Constants.CAROUSEL_HERO.map(x => (
            <WrapperItems key={x.id}
                className={'itm-carousel'}
                bgImage={`${process.env.PUBLIC_URL}/media/${isMobile ? x.image_mob : x.image}`} bgCover flex align={'center'}>
                <WrapContainer>
                    <WrapperContent>
                        <TitleH2 white dangerouslySetInnerHTML={{__html: x.title}}/>
                        <p dangerouslySetInnerHTML={{__html: x.description}}/>
                        {x.note && <p dangerouslySetInnerHTML={{__html: x.note}}/>}
                        <LinkButton
                            text={Constants.CTA_DISCOVER_SHOP}
                            url={x.href} isBlack externalLink/>
                    </WrapperContent>
                </WrapContainer>
            </WrapperItems>
        ))
    }

    function renderMiniImages(){
        return (
            <WrapperMiniImages>
                {Constants.CAROUSEL_HERO.map(x => (
                    <WrapperImage
                        key={x.id}
                        className={`${page === x.id && 'active'}`}
                        bgImage={`${process.env.PUBLIC_URL}/media/${x.image}`}
                        bgCover
                        onClick={() => handleItemCarousel(x.id)}/>
                ))}
            </WrapperMiniImages>
        );
    }

    function renderControls(){
        return (
            <WrapperControls flex align='center' justify='space-between'>
                <img src={getLocalImage('arrow-right.svg')} alt='less'
                    onClick={() => handleItemCarousel(0, -1)}/>
                <img src={getLocalImage('arrow-right.svg')} alt='add'
                    onClick={() => handleItemCarousel(0, +1)}/>
            </WrapperControls>
        );
    }

    function WrapContainer({children}: any){
        return (
            <Container>
                <Row>
                    <Col xl={5} lg={5} md={8} sm={12} xs={12}>
                        {children}
                    </Col>
                </Row>
            </Container>
        );
    }

    function handleItemCarousel(index: number, direction?: number) {
        const anmGsap = (value: number) => gsap.to('.itm-carousel', {
            x: `-${value === 0 ? 0 : value * 100}%`, duration: .8
        })
        if (direction) {
            const arrLength = Constants.CAROUSEL_HERO.length
            const newIndex = page + direction
            const idx = newIndex >= arrLength ? 0
                : newIndex < 0 ? arrLength - 1
                : newIndex
            setPage(idx)
            return anmGsap(idx)
        }
        else { 
            setPage(index)
            return anmGsap(index)
        }
    }
}

export default CarouselHero;

const WrapperCarousel = styled(Wrapper)`
    width: 100%;
    overflow: hidden;
    float: left;
    display: flex;
    flex-direction: row;
    position: relative;
`;

const WrapperItems = styled(Wrapper)`
    min-width: 100%;
    height: 70vh;
    min-height: 620px;
    padding: 50px 0!important;

    @media only screen and (max-width: 768px){
        height: 60vh;
        min-height: 520px;
    }

    p {
        color: #efefef;
        margin: 20px 0;
    }
`;

const WrapperMiniImages = styled(Wrapper)`
    position: absolute;
    right: 10%;
    bottom: 40px;
    width: fit-content;

    @media only screen and (max-width: 768px){
        position: relative;
        bottom: 0px;
        right: auto;
        left: 5%;
        margin: 40px 0;
        float: left;
    }
`;

const WrapperImage = styled(Wrapper)`
    width: 80px;
    padding-top: 80px;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0 0 60px rgba(0,0,0,.1);
    border: 1px solid rgba(255,255,255,.4);
    float: left;
    
    &:not(:last-child) {
        margin-right: 15px;
    }

    &.active {
        border: 2px solid #fff;
        box-shadow: 0 10px 60px rgba(0,0,0,.3);
    }
`;

const WrapperControls = styled(Wrapper)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    width: 96%;
    margin: 0 2%;

    img {
        user-select: none;
        cursor: pointer;
        transition: all 100ms ease-in-out;
        &:first-child {
            transform: rotate(180deg);
            &:hover {
                transform: rotate(180deg) translate(5px)
            }
        }

        &:hover:not(:first-child) {
            transform: translate(5px)
        }
    }
`;

const WrapperContent = styled(Wrapper)`
    background: #7daed3be;
    padding: 40px;
    border-radius: 8px;

    @media only screen and (max-width: 768px){
        background: #7daed3be;

        h2 {
            font-size: 20px;
        }

        p {
            font-size: 15px;
        }
    }
`;